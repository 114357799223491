<template>
  <!-- eslint-disable max-len -->
  <section class="Page PaymentMethods">
    <h2 class="Title Title--type-h2 Page-Title PaymentMethods-Title">
      {{ $t('paymentMethods.title') }} <span class="Colored">{{ $t('paymentMethods.titleColored') }}</span>
    </h2>
    <div class="Page-Introduction PaymentMethods-Introduction">
      <p>
        {{ $t('paymentMethods.text') }}
      </p>
    </div>
    <div class="PaymentMethods-Cards">
      <div v-for="item in paymentsLogos" :key="item.name" class="PaymentMethods-Card">
        <img :src="item.images.medium" alt="">
      </div>
    </div>
  </section>
</template>

<script>
import { mapState } from 'vuex';

export default {
  name: 'PaymentMethods',
  computed: {
    ...mapState(['paymentsLogos']),
  },
  metaInfo() {
    return {
      title: this.$t('metaInfo.paymentMethods.title'),
      meta: [
        {
          name: 'description',
          content:
            this.$t('metaInfo.paymentMethods.description'),
        },
      ],
    };
  },
};
</script>

<style lang="scss">
.PaymentMethods {
  padding-left: 16px;
  padding-right: 16px;

  &-Title {
    margin-bottom: 43px;
  }

  &-Introduction {
    max-width: 100%;

    @media(min-width: $screen-m) {
      max-width: 70%;
    }
  }

  &-Cards {
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-gap: 10px;

    @media(min-width: $screen-m) {
      grid-template-columns: 1fr 1fr 1fr 1fr 1fr;
    }
  }

  &-Card {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    min-height: 82px;
    padding: 0 15px;
    background-color: var(--color-bg);
    border-radius: 8px;

    @media(min-width: $screen-m) {
      min-height: 79px;
    }

    @media(min-width: $screen-l) {
      min-height: 105px;
    }

    @media(min-width: $screen-xl) {
      min-height: 135px;
    }
  }
}

</style>
